<template>
  <div>
    <!--  专属客服-->
    <a-modal width="610px" v-model="visible" :footer="null" @cancel="handleClose">
      <template slot="title">
        <span style="font-weight: 500;color: #333333;font-size: 18px">专属客服</span>
      </template>

      <div style="display: flex;color: #333333;height: 300px">
        <div v-if="isShowIcon">
          <admin_con style="font-size: 50px" type="icon-tongguo"/>
        </div>
        <div style="margin-top: 10px;margin-left: 20px">
          <div v-if="isShowIcon" style="font-size: 16px;margin-bottom: 20px">提交成功</div>
          <div>请轻松一扫您的专属客服二维码，即刻享受专属服务咨询，让问题解答更快捷！</div>
          <img style="height: 200px;margin-top: 20px" :src="KFImage" alt="">
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {admin_icon} from "@/utils/myIcon";
import {exclusiveKFImage} from "@/service/authentication";
export default {
  name: "TechnicalSupport",
  components: {
    admin_con: admin_icon,
  },
  props:{
    visible:{
      type:Boolean,
      default:false,
    },
    isShowIcon:{
      type:Boolean,
      default:true,
    },
  },
  data(){
    return{
      KFImage:'',
    }
  },
  created() {
    this.getKFImage();
  },
  methods:{
    //获取客服二维码
    async getKFImage() {
      const response = await exclusiveKFImage();
      if (response.code === 200) {
        this.KFImage = response.data;
        this.$store.dispatch("kefuImg", response.data); //客服二维码
      } else {
        this.$message.warning(response.message);
      }
    },
    handleClose(){
      let visible = false;
      this.$emit("closeMain", visible); //子组件向父组件传值（开关）
    },
    //获取单个会议内容
    // async getConvention() {
    //   const response = await conventionCode(this.meetCode);
    //   if (response.code === 0) {
    //     this.isOpenCountdown = response.data.isOpenSupport;
    //   } else {
    //     this.$message.warning(response.message)
    //   }
    // },
  }
}
</script>

<style scoped>
</style>
