<template>
  <div class="serviceConsultingManage">
    <a-spin :spinning="spin">
      <div class="top">
        <!-- 第二部分 -->
        <div class="selectTop">
          <div style="display: flex">
            <span class="topTitle">服务状态：</span>
            <a-radio-group v-model="status" button-style="solid" @change="selectStatus">
<!--              consult咨询中，pending 未开始，start 进行中，success 已完成 cancel 已取消-->
              <a-radio-button value="">
                全   部
              </a-radio-button>
              <a-radio-button value="consult">
                咨询中
              </a-radio-button>
              <a-radio-button value="pending">
                待开始
              </a-radio-button>
              <a-radio-button value="start">
                进行中
              </a-radio-button>
              <a-radio-button value="success">
                已完成
              </a-radio-button>
              <a-radio-button value="cancel">
                已取消
              </a-radio-button>
            </a-radio-group>
          </div>
        </div>
      </div>
      <div style="width: 100%; height: 10px; background: #edeff2"></div>
      <div class="bottom">
        <!-- 顶部 -->
        <div style="border: 1px solid #edeff2; margin-bottom: 15px">
          <div class="moTitle">
            <div class="topTitle font">服务咨询列表</div>
          </div>
        </div>
        <a-table :columns="columns" :data-source="data" :pagination="false">
           <span slot="rank" slot-scope="text, record,index">
             <span>{{(pagination.curPage-1)*10+index+1}}</span>
           </span>
          <span slot="expireDate" slot-scope="text, record,index">
            {{record.expireDate?record.expireDate:'-'}}
          </span>
           <span slot="discountPrice" slot-scope="text, record">
             <span v-if="(record.orderStatus === '待付款' && record.discountPrice === 0) || (record.orderStatus === '已取消' && record.serviceStatus === 'cancel')">-</span>
             <span v-else>￥{{text}}</span>
           </span>
           <span slot="serviceStatus" slot-scope="text, record">
<!--             consult咨询中，pending未开始，start进行中，success已完成 cancel已取消-->
             <span v-if="text === 'consult'">咨询中</span>
             <span v-if="text === 'pending'" style="color: dodgerblue">待开始</span>
             <span v-if="text === 'start'" style="color: orange">进行中</span>
             <span v-if="text === 'success'" style="color: #00c700">已完成</span>
             <span v-if="text === 'cancel'">已取消</span>
             <span v-if="text === 'expire'">已到期</span>
           </span>
           <span slot="orderStatus" slot-scope="text, record">
             <span v-if="record.orderStatus === '待付款' && record.discountPrice === 0">-</span>
             <span v-else>{{text}}</span>
           </span>
           <span slot="action" slot-scope="text, record">
             <a @click="issueLive(record)" v-if="record.serviceStatus === 'consult' && record.discountPrice && record.orderStatus !== '待确认'" style="margin-right: 8px;">付款</a>
             <a-popconfirm
                 v-if="record.serviceStatus === 'consult'"
                 style="margin-right: 8px;"
                 title="是否确认取消订单?"
                 ok-text="是"
                 cancel-text="否"
                 @confirm="cancelConfirm(record)"
             ><a>取消订单</a></a-popconfirm>

             <a @click="openLog(record)" v-if="record.serviceStatus === 'start' || record.serviceStatus === 'success'"  style="margin-right: 8px;">服务记录</a>

             <a v-if="record.serviceStatus === 'pending'" @click="openCustomer">咨询客服</a>

             <a @click="openEvaluate(record)" v-if="record.serviceStatus === 'success'"  style="margin-right: 8px;">评价</a>

             <a-popconfirm
                 v-if="record.serviceStatus === 'cancel'"
                 style="margin-right: 8px;"
                 title="是否确认删除?"
                 ok-text="是"
                 cancel-text="否"
                 @confirm="delConfirm(record)"
             ><a>删除</a></a-popconfirm>

             <span v-if="record.serviceStatus === 'expire'">-</span>
           </span>
        </a-table>
      </div>

      <!-- 分页 -->
      <div style="display: flex; justify-content: center; margin-top: 20px">
        <a-pagination
            :current="pagination.curPage"
            :total="pagination.total"
            :pageSize="pagination.pageSize"
            show-quick-jumper
            @change="PageNOchange"
        />
      </div>

<!--      评价-->
      <Evaluate
          v-if="visibleEvaluate"
          :visible="visibleEvaluate"
          :serviceCode="serviceCode"
          :serConId="serConId"
          :orderNumber="orderNumber"
          v-on:closeMain="closeMain"
      />
<!--      服务记录-->
      <a-drawer
          :get-container="false"
          @close="cancel"
          :width="800"
          :body-style="{ paddingBottom: '80px' }"
          :visible="visible"
          title="服务记录">
        <a-table :columns="columnsLog" :data-source="dataLog" :pagination="false">
          <span slot="serviceStatus" slot-scope="text, record,index">
<!--            //待开始 pending进行中 start已完成 success  -->
            <span v-if="text === 'pending'">待开始</span>
            <span v-if="text === 'start'">进行中</span>
            <span v-if="text === 'success'">已完成</span>
           </span>
          <span slot="serviceStartTime" slot-scope="text, record,index">
            <span>{{text.slice(0, 10)}} ~ {{record.serviceEndTime.slice(0, 10)}}</span>
          </span>
        </a-table>
        <!-- 分页 -->
        <div style="display: flex; justify-content: center; margin-top: 20px">
          <a-pagination
              :current="paginationDrawer.curPage"
              :total="paginationDrawer.total"
              :pageSize="paginationDrawer.pageSize"
              show-quick-jumper
              @change="PageDrawerchange"
          />
        </div>
      </a-drawer>

      <!--    订单支付  -->
      <pay-indent
          v-if="payVisible"
          :orderNumber="orderNumber"
          :visible="payVisible"
          v-on:closeMain="closeMainPay"
      />

      <!--  专属客服-->
      <ServiceExclusive
          v-if="visibleExclusives"
          :visible="visibleExclusives"
          :isShowIcon="false"
          v-on:closeMain="closeMainExclusives"
      />
    </a-spin>
  </div>
</template>

<script>
import ServiceExclusive from "@/components/PaidView/ServiceExclusive";
import variables from "@/assets/base.scss";
import Evaluate from "@/components/PaidView/Evaluate";
import {
  cancellationByOrderNumber,
  delServiceConsultList,
  selectRecordList,
  serviceConsultList
} from "@/service/authentication";
import payIndent from "@/components/PaidView/payIndent";

const columns = [
  {
    title: '序号',
    key: 'rank',
    scopedSlots: { customRender: 'rank' },
  }, {
    title: '服务名称',
    dataIndex: 'serviceName',
    key: 'serviceName',
  },{
    title: '提交时间',
    dataIndex: 'createdTime',
    key: 'createdTime',
  },{
    title: '服务到期时间',
    dataIndex: 'expireDate',
    key: 'expireDate',
    scopedSlots: { customRender: 'expireDate' },
  },{
    title: '订单号',
    dataIndex: 'orderNumber',
    key: 'orderNumber',
  },{
    title: '订单金额',
    dataIndex: 'discountPrice',
    key: 'discountPrice',
    scopedSlots: { customRender: 'discountPrice' },
  },{
    title: '订单状态',
    dataIndex: 'orderStatus',
    key: 'orderStatus',
    scopedSlots: { customRender: 'orderStatus' },
  },{
    title: '服务状态',
    dataIndex: 'serviceStatus',
    key: 'serviceStatus',
    scopedSlots: { customRender: 'serviceStatus' },
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];

const data = [];
const columnsLog = [
  {
    title: '服务时间',
    key: 'serviceStartTime',
    dataIndex: 'serviceStartTime',
    scopedSlots: { customRender: 'serviceStartTime' },
  },
  {
    title: '服务内容',
    dataIndex: 'serviceContent',
    key: 'serviceContent',
  },
  // {
  //   title: '状态',
  //   key: 'serviceStatus',
  //   scopedSlots: { customRender: 'serviceStatus' },
  // },
];

const dataLog = [];
export default {
  name: "serviceConsultingManage",
  components: {
    Evaluate,
    payIndent,
    ServiceExclusive
  },
  data() {
    return {
      visibleExclusives:false,
      formPath:'serviceConsultingManage',
      LogId:null,
      columnsLog,
      dataLog,
      spin: false,
      visible: false,
      visibleEvaluate: false,
      serviceCode: '',
      serConId: null,
      variables,
      pagination:{
        curPage:1,
        pageSize:10,
        total:0,
      },
      paginationDrawer:{
        curPage:1,
        pageSize:10,
        total:0,
      },
      status:'',
      data,
      columns,
      payVisible:false,
      orderNumber:'',
    };
  },
  created() {
    this.getServiceConsultList();
  },
  methods: {
    selectStatus(){
      this.pagination.curPage  = 1;
      this.getServiceConsultList();
    },
    PageDrawerchange(pageNo,pageSize){
      this.paginationDrawer.curPage = pageNo;
      this.paginationDrawer.pageSize = pageSize;
      this.getSelectRecordList();
    },
    //根据服务咨询id查询服务记录
    async getSelectRecordList(id) {
      let data = {
        page:this.paginationDrawer.curPage,
        pageSize:this.paginationDrawer.pageSize,
      }
      const response = await selectRecordList(id,data)
      if(response.code === 200){
        this.dataLog = response.data;
        this.paginationDrawer.total = response.count
      }else {
        this.$message.warning(response.message);
      }
    },
    async getServiceConsultList() {
      this.spin = true;
      let data = {
        page:this.pagination.curPage,
        pageSize:this.pagination.pageSize,
        status:this.status,
      }
      const response = await serviceConsultList(data)
      if(response.code === 200){
        this.data = response.data;
        this.pagination.total = response.count;
      }else {
        this.$message.warning(response.message);
      }
      this.spin = false;
    },
    async issueLive(row) {
      this.orderNumber = row.orderNumber;
      this.payVisible = true;
    },
    closeMainPay(val){
      this.payVisible = val;
      this.getServiceConsultList();
    },
    async cancelOrderNumber(data) {
      const response = await cancellationByOrderNumber(data)
      if(response.code === 200){
        this.$message.success("订单取消成功！");
        this.getServiceConsultList();
      }else {
        this.$message.warning(response.message);
      }
    },
    openLog(row){
      this.LogId = row.id;
      console.log(row.id)
      this.getSelectRecordList(row.id);
      this.visible = true;
    },
    cancel(){
      this.visible = false;
      this.paginationDrawer.curPage = 1;
    },
    PageNOchange(pageNo,pageSize){
      this.pagination.curPage = pageNo;
      this.pagination.pageSize = pageSize;
      this.getServiceConsultList();
    },
    async delConfirm(row) {
      const response = await delServiceConsultList(row.id)
      if (response.code === 200) {
        this.$message.success("订单删除成功！");
        await this.getServiceConsultList();
      } else {
        this.$message.warning(response.message);
      }
    },
    cancelConfirm(row){
      let data = {
        "orderNumber":row.orderNumber,
        "cancelType":"商户取消"
      }
      this.cancelOrderNumber(data)
    },
    closeMain(val){
      this.visibleEvaluate = val;
    },
    openEvaluate(row){
      this.orderNumber = row.orderNumber;
      this.serviceCode = row.subscriptionServicesCode;
      this.serConId = row.id;
      this.visibleEvaluate = true;
    },
    openCustomer(){
      this.visibleExclusives = true;
    },
    closeMainExclusives(val) {
      this.visibleExclusives = val;
    },
  }
};
</script>

<style scoped lang="scss">
.ant-col-5 {
  width: 20%;
}
.ant-col-4 {
  width: 16.66666%;
}

.serviceConsultingManage {
  width: 100%;
  min-height: 100%;
  border: 10px solid #edeff2;
}
.top {
  width: 100%;
  height: 50px;

  .selectTop {
    width: 98%;
    height: 50%;
    margin: auto;
    display: flex;
    line-height: 30px;
    margin-top: 15px;
  }
}
.topTitle {
  color: $topTitleColor;
}

.bottom {
  width: 100%;
  min-height: 480px;
}

.moTitle {
  display: flex;
  justify-content: space-between;
  width: 98%;
  margin: auto;
  height: 50px;
  line-height: 50px;
  .font {
    font-weight: 600;
    color: $topNameColor;
  }
}

</style>
