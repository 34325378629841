<template>
<div>
<!--  评价-->
  <a-modal v-model="visible" :dialog-style="{ top: '50px' }" width="800px" :footer="null" @cancel="handleClose">
    <template slot="title">
      <span style="font-weight: 550;color: #333333;font-size: 18px">评价</span>
    </template>
    <!--    只展示数据不进行点击操作-->
    <div v-if="!isShow" class="contents">
      <div class="contentTop">
        <div class="topLeft">
          <img src="../../assets/paidImg/service.png"  class="topImg"/>
        </div>
        <div class="topRight">
          <div class="rightTitle">{{serviceInfo.name}}</div>
          <div class="rightCont">{{serviceInfo.shortIntro}}</div>
        </div>
      </div>
      <div class="mod">
        <div class="experience">
          <div class="experienceLeft"></div>
          <div class="experienceLeftR" style="margin-right: 10px"></div>
          <div style="margin-right: 10px;color: #333333;font-weight: bold">本次服务体验</div>
          <div class="experienceLeftR"></div>
          <div class="experienceLeft"></div>
        </div>
        <div class="experienceIcon">
          <div class="iconDiv">
            <div>
              <img v-if="evaluate.serviceEvaluate === '非常满意'" class="icon" src="../../assets/paidImg/greatSatisfaction.png" alt="">
              <img v-else class="icon" src="../../assets/paidImg/NoGreatSatisfaction.png" alt="">
            </div>
            <div class="iconFont">非常满意</div>
          </div>

          <div class="iconDiv">
            <div>
              <img v-if="evaluate.serviceEvaluate === '满意'" class="icon" src="../../assets/paidImg/satisfaction.png" alt="">
              <img v-else class="icon" src="../../assets/paidImg/NoSatisfaction.png" alt="">
            </div>
            <div class="iconFont">满意</div>
          </div>

          <div class="iconDiv">
            <div>
              <img v-if="evaluate.serviceEvaluate === '不满意'" class="icon" src="../../assets/paidImg/dissatisfaction.png" alt="">
              <img v-else class="icon" src="../../assets/paidImg/NoDissatisfaction.png" alt="">
            </div>
            <div class="iconFont">不满意</div>
          </div>

          <div class="iconDiv">
            <div>
              <img v-if="evaluate.serviceEvaluate === '糟糕'" class="icon" src="../../assets/paidImg/bad.png" alt="">
              <img v-else class="icon" src="../../assets/paidImg/NoBad.png" alt="">
            </div>
            <div class="iconFont">糟糕</div>
          </div>
        </div>
      </div>

      <div class="bottom">
        <div class="bottom-top">
          <div style="font-weight: bold;">其他服务评价</div>
          <div>满意请给五颗星哦~</div>
        </div>
        <div style="width: 95%;margin: auto;padding-top: 20px;display: flex">
          <div>客服评价</div>
          <div style="margin-left: 20px">
            <admin_con class="serviceIcon" :type="evaluate.service >= 1 ? 'icon-xingxing1':'icon-xingxing'"/>
            <admin_con class="serviceIcon" :type="evaluate.service >= 2 ? 'icon-xingxing1':'icon-xingxing'"/>
            <admin_con class="serviceIcon" :type="evaluate.service >= 3 ? 'icon-xingxing1':'icon-xingxing'"/>
            <admin_con class="serviceIcon" :type="evaluate.service >= 4 ? 'icon-xingxing1':'icon-xingxing'"/>
            <admin_con class="serviceIcon" :type="evaluate.service >= 5 ? 'icon-xingxing1':'icon-xingxing'"/>
          </div>
        </div>
        <div style="margin-left: 95px;margin-top: 10px;">
          <!--         热情礼貌、细心可靠、专业高效、回复及时、积极主动-->
          <a-tag @click="selectFont('热情礼貌')" :color="evaluate.serviceFont.indexOf('热情礼貌') > -1 ? 'orange':''" size="small">态度积极</a-tag>
          <a-tag @click="selectFont('细心可靠')" :color="evaluate.serviceFont.indexOf('细心可靠') > -1 ? 'orange':''" size="small">乐于助人</a-tag>
          <a-tag @click="selectFont('专业高效')" :color="evaluate.serviceFont.indexOf('专业高效') > -1 ? 'orange':''" size="small">礼貌热情</a-tag>
          <a-tag @click="selectFont('回复及时')" :color="evaluate.serviceFont.indexOf('回复及时') > -1 ? 'orange':''" size="small">礼貌热情</a-tag>
          <a-tag @click="selectFont('积极主动')" :color="evaluate.serviceFont.indexOf('积极主动') > -1 ? 'orange':''" size="small">礼貌热情</a-tag>
        </div>
        <div style="width: 95%;margin: auto;padding-top: 20px;display: flex">
          <div>系统评价</div>
          <div style="margin-left: 20px">
            <admin_con  class="serviceIcon" :type="evaluate.system >= 1 ? 'icon-xingxing1':'icon-xingxing'"/>
            <admin_con  class="serviceIcon" :type="evaluate.system >= 2 ? 'icon-xingxing1':'icon-xingxing'"/>
            <admin_con  class="serviceIcon" :type="evaluate.system >= 3 ? 'icon-xingxing1':'icon-xingxing'"/>
            <admin_con  class="serviceIcon" :type="evaluate.system >= 4 ? 'icon-xingxing1':'icon-xingxing'"/>
            <admin_con  class="serviceIcon" :type="evaluate.system >= 5 ? 'icon-xingxing1':'icon-xingxing'"/>
          </div>
        </div>
        <div style="width: 95%;margin: auto;padding-top: 20px;">
          <div>反馈与建议</div>
          <a-textarea
              :disabled="true"
              v-model="evaluate.suggestions"
              style="margin-top: 10px"
              placeholder="请输入"
              :auto-size="{ minRows: 5, maxRows: 10 }"
          />
        </div>
      </div>
    </div>
<!--    可以操作-->
   <div v-else class="contents">
     <div class="contentTop">
       <div class="topLeft">
         <img src="../../assets/paidImg/service.png"  class="topImg"/>
       </div>
       <div class="topRight">
         <div class="rightTitle">{{serviceInfo.name}}</div>
         <div class="rightCont">{{serviceInfo.shortIntro}}</div>
       </div>
     </div>
     <div class="mod">
       <div class="experience">
         <div class="experienceLeft"></div>
         <div class="experienceLeftR" style="margin-right: 10px"></div>
         <div style="margin-right: 10px;color: #333333;font-weight: bold">本次服务体验</div>
         <div class="experienceLeftR"></div>
         <div class="experienceLeft"></div>
       </div>
       <div class="experienceIcon">
         <div class="iconDiv" @click="selectIconBtn('非常满意')">
           <div>
             <img v-if="evaluate.serviceEvaluate === '非常满意'" class="icon" src="../../assets/paidImg/greatSatisfaction.png" alt="">
             <img v-else class="icon" src="../../assets/paidImg/NoGreatSatisfaction.png" alt="">
           </div>
           <div class="iconFont">非常满意</div>
         </div>

         <div class="iconDiv" @click="selectIconBtn('满意')">
           <div>
             <img v-if="evaluate.serviceEvaluate === '满意'" class="icon" src="../../assets/paidImg/satisfaction.png" alt="">
             <img v-else class="icon" src="../../assets/paidImg/NoSatisfaction.png" alt="">
           </div>
           <div class="iconFont">满意</div>
         </div>

         <div class="iconDiv" @click="selectIconBtn('不满意')">
           <div>
             <img v-if="evaluate.serviceEvaluate === '不满意'" class="icon" src="../../assets/paidImg/dissatisfaction.png" alt="">
             <img v-else class="icon" src="../../assets/paidImg/NoDissatisfaction.png" alt="">
           </div>
           <div class="iconFont">不满意</div>
         </div>

         <div class="iconDiv" @click="selectIconBtn('糟糕')">
           <div>
             <img v-if="evaluate.serviceEvaluate === '糟糕'" class="icon" src="../../assets/paidImg/bad.png" alt="">
             <img v-else class="icon" src="../../assets/paidImg/NoBad.png" alt="">
           </div>
           <div class="iconFont">糟糕</div>
         </div>
       </div>
     </div>

     <div class="bottom">
       <div class="bottom-top">
         <div style="font-weight: bold;">其他服务评价</div>
         <div>满意请给五颗星哦~</div>
       </div>
       <div style="width: 95%;margin: auto;padding-top: 20px;display: flex">
         <div>客服评价</div>
         <div style="margin-left: 20px">
           <admin_con @click="selectService(1)" class="serviceIcon" :type="evaluate.service >= 1 ? 'icon-xingxing1':'icon-xingxing'"/>
           <admin_con @click="selectService(2)" class="serviceIcon" :type="evaluate.service >= 2 ? 'icon-xingxing1':'icon-xingxing'"/>
           <admin_con @click="selectService(3)" class="serviceIcon" :type="evaluate.service >= 3 ? 'icon-xingxing1':'icon-xingxing'"/>
           <admin_con @click="selectService(4)" class="serviceIcon" :type="evaluate.service >= 4 ? 'icon-xingxing1':'icon-xingxing'"/>
           <admin_con @click="selectService(5)" class="serviceIcon" :type="evaluate.service >= 5 ? 'icon-xingxing1':'icon-xingxing'"/>
         </div>
       </div>
       <div style="margin-left: 95px;margin-top: 10px;">
<!--         热情礼貌、细心可靠、专业高效、回复及时、积极主动-->
         <a-tag @click="selectFont('热情礼貌')" :color="evaluate.serviceFont.indexOf('热情礼貌') > -1 ? 'orange':''" size="small">态度积极</a-tag>
         <a-tag @click="selectFont('细心可靠')" :color="evaluate.serviceFont.indexOf('细心可靠') > -1 ? 'orange':''" size="small">乐于助人</a-tag>
         <a-tag @click="selectFont('专业高效')" :color="evaluate.serviceFont.indexOf('专业高效') > -1 ? 'orange':''" size="small">礼貌热情</a-tag>
         <a-tag @click="selectFont('回复及时')" :color="evaluate.serviceFont.indexOf('回复及时') > -1 ? 'orange':''" size="small">礼貌热情</a-tag>
         <a-tag @click="selectFont('积极主动')" :color="evaluate.serviceFont.indexOf('积极主动') > -1 ? 'orange':''" size="small">礼貌热情</a-tag>
       </div>
       <div style="width: 95%;margin: auto;padding-top: 20px;display: flex">
         <div>系统评价</div>
         <div style="margin-left: 20px">
           <admin_con @click="selectSystem(1)" class="serviceIcon" :type="evaluate.system >= 1 ? 'icon-xingxing1':'icon-xingxing'"/>
           <admin_con @click="selectSystem(2)" class="serviceIcon" :type="evaluate.system >= 2 ? 'icon-xingxing1':'icon-xingxing'"/>
           <admin_con @click="selectSystem(3)" class="serviceIcon" :type="evaluate.system >= 3 ? 'icon-xingxing1':'icon-xingxing'"/>
           <admin_con @click="selectSystem(4)" class="serviceIcon" :type="evaluate.system >= 4 ? 'icon-xingxing1':'icon-xingxing'"/>
           <admin_con @click="selectSystem(5)" class="serviceIcon" :type="evaluate.system >= 5 ? 'icon-xingxing1':'icon-xingxing'"/>
         </div>
       </div>
       <div style="width: 95%;margin: auto;padding-top: 20px;">
         <div>反馈与建议</div>
         <a-textarea
             v-model="evaluate.suggestions"
             style="margin-top: 10px"
             placeholder="请输入"
             :auto-size="{ minRows: 5, maxRows: 10 }"
         />
       </div>
     </div>
   </div>

    <div class="bottomSty" v-if="isShow && isShowBtn">
      <a-button @click="handleClose">
        取消
      </a-button>
      <a-button style="margin-left: 10px" type="primary" @click="handleOk">
        提交
      </a-button>
    </div>
  </a-modal>

</div>
</template>

<script>
import {admin_icon} from "@/utils/myIcon";
import {evaluateService, getServiceInfo, selectByConId} from "@/service/authentication";
export default {
  name: "ServiceIntroduction",
  components:{
    admin_con: admin_icon,
  },
  props:{
    visible:{
      type:Boolean,
      default:false,
    },
    isShowBtn:{
      type:Boolean,
      default:true,
    },
    serviceCode:{
      type:String,
      default:'',
    },
    serConId:{
      type:Number,
    },
    orderNumber:{
      type:String,
    },
  },
  data(){
    return{
      evaluate:{
        serviceEvaluate:'非常满意',
        service:0, //客服评价
        system:0, //系统评价
        suggestions:'', //反馈与建议
        serviceFont:[], //客服评价词
      },
      isShow:true,
      serviceInfo:{}
    }
  },
  created() {
    // if(this.serConId){
      this.getEvaluateService();
      this.getInformation();
    // }
  },
  methods:{
    //获取服务介绍
    async getInformation() {
      const response = await getServiceInfo(this.serviceCode);
      if (response.code === 200) {
        this.serviceInfo = response.data;
      } else {
        this.$message.warning(response.message);
      }
    },
    //获取单个评价
    async getEvaluateService() {
      const response = await selectByConId(this.serConId)
      if(response.code === 200){
        // 回显评价
        this.evaluate = {
          serviceEvaluate:response.data.serviceEvaluate ? response.data.serviceEvaluate : '',
          service:response.data.kfEvaluate ? response.data.kfEvaluate : null, //客服评价
          system:response.data.systemEvaluate ? response.data.systemEvaluate : null, //系统评价
          suggestions:response.data.suggestions ? response.data.suggestions : '', //反馈与建议
          serviceFont:response.data.evaluate ? response.data.evaluate : [], //客服评价词
        }
        this.isShow = false;
      }else {
        this.$message.warning(response.message);
      }
    },
    handleOk() {
      let data = {
        "serviceCode": this.serviceCode, //评价服务code
        "orderNumber": this.orderNumber, //评价服务code
        "serviceEvaluate": this.evaluate.serviceEvaluate, //服务评价
        "evaluate": this.evaluate.serviceFont, //评价 客服评价词
        "kfEvaluate": this.evaluate.service, //客服评价
        "systemEvaluate": this.evaluate.system, //系统评价
        "suggestions": this.evaluate.suggestions, //系统评价
        "serConId": this.serConId//评价服务咨询列表id
      }
      this.evaluateServices(data);
    },
    async evaluateServices(data) {
      const response = await evaluateService(data)
      if(response.code === 200){
        this.$message.success("评价成功！");
        this.handleClose();
      }else {
        this.$message.warning(response.message);
      }
    },
    handleClose(){
      let visible = false;
      this.$emit("closeMain", visible); //子组件向父组件传值（开关）
    },
    selectIconBtn(val){
      this.evaluate.serviceEvaluate = val;
    },
    selectFont(val){
      //判断包括此评价则删除，不包括则添加
      if(this.evaluate.serviceFont.indexOf(val) > -1){ //包括
        this.evaluate.serviceFont.splice(this.evaluate.serviceFont.indexOf(val),1)
      }else {
        this.evaluate.serviceFont.push(val);
      }
    },
    selectService(num){
      //判断，当前点击
      this.evaluate.service = num;
    },
    selectSystem(num){
      //判断，当前点击
      this.evaluate.system = num;
    },
  }
}
</script>

<style scoped>
.bottomSty {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background-color: #fff;
  display: flex;
  justify-content: right;
  z-index: 1;
}
.contents{
  height: 700px;
  overflow-y: scroll;
}
.contentTop{
  display: flex;
  justify-content: space-between;
}
.topLeft{

}
.topRight{
  width: 530px;
}
.rightTitle{
  font-weight: bold;
  margin-bottom: 10px;
  color: #333333;
}
.rightCont{
  color: #333333;
  margin-bottom: 10px;
}
.topImg{
  width: 200px;
}
.piceImg{
  width: 400px;
  height: 50px;
}
.mod{
  width: 100%;
  border: 1px solid #f5f5f5;
  margin-top: 30px;
  height: 150px;
  margin-bottom: 10px;
  box-shadow: 1px 1px 15px #eae9e9;
}
.bottom{
  width: 100%;
  border: 1px solid #f5f5f5;
  height: 330px;
  box-shadow: 1px 1px 15px #eae9e9;
}
.experience{
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.experienceLeft{
  width: 60px;
  height: 2px;
  background-color: #d9d9d9;
  margin-top: 10px;
}
.experienceLeftR{
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-top: 6px;
  background-color: #d9d9d9;
}
.experienceIcon{
  width: 60%;
  margin: auto;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.iconDiv{
  cursor: pointer;
}
.icon{
  width: 50px;
}
.iconFont{
  margin-top: 5px;
  text-align: center;
}
.bottom-top{
  width: 98%;
  margin: auto;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  color: #333333;
}
.serviceIcon{
  margin-right: 5px;
  cursor: pointer;
}
</style>
