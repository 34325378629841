import { render, staticRenderFns } from "./ServiceExclusive.vue?vue&type=template&id=5ff607ba&scoped=true&"
import script from "./ServiceExclusive.vue?vue&type=script&lang=js&"
export * from "./ServiceExclusive.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ff607ba",
  null
  
)

export default component.exports