<template>
  <div>
    <!--  订单支付  -->
    <a-modal width="100%" :dialog-style="{ top: '0px' }" v-model="visible" :footer="null" @cancel="handleClose">
      <template slot="title">
        <span style="font-weight: 550;color: #333333;font-size: 20px">订单支付</span>
      </template>
      <div :style="{height: (heightModel-120)+'px',backgroundColor:'#ffffff',overflowY:'scroll'}">
        <div class="Merchandise"><span style="font-weight: bold">商品订单：</span> {{IndentCont.orderNumber}}</div>
        <div class="Merchandise" style="margin-top: 10px"><span style="font-weight: bold">支付金额：</span>
          <span style="color: #00C024">
            ￥{{IndentCont.orderPayMoney}}
          </span> <span style="cursor: pointer" @click="isShowContBtn(isShowCont ? false:true)">订单详情</span> <admin_con @click="isShowContBtn(isShowCont ? false:true)" v-if="isShowCont" style="cursor: pointer;" type="icon-sanjiaoxia"/> <admin_con @click="isShowContBtn(isShowCont ? false:true)" v-else style="cursor: pointer;" type="icon-sanjiaoshang"/>
        </div>
        <div class="particulars" v-if="isShowCont">
          <div class="particulars_font">商品名称:  {{IndentCont.orderName}}</div>
          <div class="particulars_font">支付订单:  {{IndentCont.orderNumber}}</div>
          <div class="particulars_font">应付金额: ￥{{IndentCont.orderPayMoney}}</div>
          <div class="particulars_font">购买时间:  {{IndentCont.orderTime}}</div>
        </div>
        <div style="width: 100%;height: auto">
          <div class="bottomBtn">
            <div class="bottomLeftBtn" @click="selectPaymentMethod('weiXin')" :style="{border:paymentMethod === 'weiXin'?'2px solid #0081FF':'1px solid #d9d9d9'}">
              <admin_con class="btnIcon" type="icon-weixinfukuan"/>
              <div class="btnFont">微信付款</div>
              <admin_con v-if="paymentMethod === 'weiXin'" class="xuanzhongbiankuang" type="icon-xuanzhongbiankuang"/>
            </div>
            <div class="bottomLeftBtn" style="margin-left: 50px" @click="selectPaymentMethod('forCorporate')" :style="{border:paymentMethod === 'forCorporate'?'2px solid #0081FF':'1px solid #d9d9d9'}">
              <admin_con class="btnIcon" type="icon-gonghuchongzhi"/>
              <div class="btnFont">对公支付</div>
              <admin_con v-if="paymentMethod === 'forCorporate'" class="xuanzhongbiankuang" type="icon-xuanzhongbiankuang"/>
            </div>
          </div>

          <!--            微信-->
          <div v-if="paymentMethod === 'weiXin'">
              <div style="display: flex">
                <div>
                  <div id="qrCode" style="background-color: #fff;">
                    <vue-qr
                        ref="image"
                        :logo-src="logoSrc"
                        :size="200"
                        :margin="0"
                        :auto-color="true"
                        :dot-scale="1"
                        :text="appSrc" />
                  </div>
                  <div class="fontSty">请打开手机微信，扫一扫完成支付</div>
                </div>

                <div>
                  <img style="width: 180px;margin-left: 20px" src="../../assets/paidImg/img.png" alt="">
                </div>
              </div>
          </div>
          <!--            对公-->
          <div v-if="paymentMethod === 'forCorporate'">
            <div :style="{width: '100%'}">
              <a-alert message="线下支付到账有延迟，并需提交银行回执予财务审核。" type="warning" show-icon />
              <a-alert type="info" style="margin-top: 20px">
                <div slot="message" style="font-size: 20px;font-weight: bold">对公帐号</div>
                <div slot="description">
                  <p style="margin-top: 10px">户 名: 广州市优屏科技有限公司</p>
                  <p>开户行: 中国民生银行华南支行</p>
                  <p>账 号: 695281930</p>
                </div>
              </a-alert>
              <a-alert type="info" style="margin-top: 20px">
                <div slot="message"></div>
                <div slot="description">
                  <p>如果您已打款至以上帐号，请上传银行回执。</p>
                  <p>各大银行对公转账到账时间需要2小时～3个工作日不等。</p>
                  <p>我们的工作人员会在收到付款后第一时间为您开通服务，并通过短信通知您</p>
                  <div style="position: relative">
                    <a-button>上传银行回执</a-button>
                    <div class="upload">
                      <input
                          id="uploadImgId"
                          style="position: absolute;top: 0;left: 0;border: #333333 1px solid;width: 115px;height: 30px;opacity: 0"
                          type="file"
                          @change="uploadImg"
                          ref="uploadImg"/>
                    </div>

                  </div>
                  <p>如有疑问，请联系客服，电话: 18122188708， 邮箱: pansongan@yichimeeting.com</p>
                </div>
              </a-alert>
              <a-alert type="error" style="margin-top: 20px">
                <div slot="message" style="font-size: 20px;font-weight: bold">对公转账发票申请注意事项：</div>
                <div slot="description">
                  <p style="margin-top: 10px">个人帐号转账至我司对公帐号只能开具个人普通发票。</p>
                  <p>公司帐号转账至我司对公帐号可开具企业增值税普通发票或企业增值税专用发票。</p>
                </div>
              </a-alert>
            </div>
          </div>

        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {admin_icon} from "@/utils/myIcon";
// 在需要生成二维码的文件中引入比如qrCode.vue
import VueQr from 'vue-qr';
import {update} from "@/utils/update";
import {publishedConvention, uploadBankReceipt} from "@/service/authentication";
import {conventionNative, getPayIndent, getPayResult} from "@/service/MedicalConference";
export default {
  name: "payIndent",
  components:{
    admin_con: admin_icon,
    VueQr
  },
  props:{
    visible:{
      type:Boolean,
      default:false,
    },
    orderNumber: {
      type: String,
      default:'',
    },
    //支付成功后是否调用发布会议接口
    publishedConvention:{
      type:Boolean,
      default:false,
    },
    conferenceCode:{
      type:String,
      default:'',
    },
  },
  created() {
    this.heightModel = document.documentElement.clientHeight;
    if(this.orderNumber){
      this.getIndentCont();
      this.getConventionNative();
    }
  },
  data(){
    return{
      heightModel:null,
      paymentMethod:'weiXin', //weiXin  forCorporate
      logoSrc:"",
      isShowCont:true,
      appSrc:'',
      IndentCont:{},
      paying:true,
    }
  },

  methods:{
    //每秒请求一次接口，获取支付状态
    startPollingStatus() {
      this.intervalId = setInterval(() => {
        if(this.paying){
          this.stateOfPayment();
        }
      }, 2000); // 每2秒查询一次支付状态
    },
    async stateOfPayment() {
      const response = await getPayResult(this.orderNumber);
      if (response.code === 200) {
        //刷新
        this.$message.success("支付成功！");

        //支付成功后是否调用发布会议接口
        if(this.publishedConvention){
          await this.publishedConventions();
        }
        this.paying = false; //订单支付成功后则不进行订单支付回调检查
        //关闭对话框
        this.handleClose();
        clearInterval(this.intervalId);
      }
    },
    async publishedConventions() {
      const response = await publishedConvention(this.conferenceCode);
      if (response.code === 200) {
        this.$message.success("发布会议！");
        //会议发布-会议状态改为已发布状态
        this.$store.dispatch("isShowIssueBtn", true);
      } else {
        this.$message.warning(response.message);
      }
    },
    //获取订单详情
    async getIndentCont() {
      const response = await getPayIndent(this.orderNumber);
      if (response.code === 200) {
        //刷新
        this.IndentCont = response.data;
      } else {
        this.$message.warning(response.message);
      }
    },
    //获取二维码图片
    async getConventionNative() {
      const response = await conventionNative(this.orderNumber);
      if (response.code === 200) {
        //刷新
        this.appSrc = response.data;
        this.startPollingStatus();
      } else {
        this.$message.warning(response.message);
      }
    },
    handleClose(){
      let visible = false;
      this.$emit("closeMain", visible); //子组件向父组件传值（开关）
    },
    selectPaymentMethod(val){
      this.paymentMethod = val;
    },
    async uploadImg() {
      let inputDOM = this.$refs.uploadImg.files[0];
      const image = await update(inputDOM)
      if (image.code === 0) {
        let data = {
          "orderNumber":this.orderNumber,//订单id
          "bankReceipt": image.data.url//银行回执单图片
        }
        await this.upBankReceipt(data)
      } else {
        this.$message.warning("上传失败");
      }
      document.getElementById('uploadImgId').value = null;
    },
    //上传银行回执
    async upBankReceipt(data) {
      const response = await uploadBankReceipt(data);
      if (response.code === 200) {
        this.$message.success("上传成功");

        //支付成功后是否调用发布会议接口
        // if(this.publishedConvention){
        //   await this.publishedConventions();
        // }
        this.paying = false; //订单支付成功后则不进行订单支付回调检查
        //关闭对话框
        this.handleClose();

        clearInterval(this.intervalId);

      }else {
        this.$message.warning(response.message);
      }
    },
    isShowContBtn(isShowCont){
      this.isShowCont = isShowCont;
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
}
</script>

<style scoped>
.Merchandise{
  font-size: 18px;color: #333333;
}
.particulars{
  color: #333333;margin-left: 95px;font-size: 16px;
}
.particulars_font{
  margin-top: 7px;
}
.bottomBtn{
  font-size: 16px;
  color: #333333;
  display: flex;
  margin-top: 30px;
  border-top: 1px solid #eaeaea;
  padding-top: 30px;
}
.bottomLeftBtn{
  width: 150px;
  cursor: pointer;
  height: 45px;
  text-align: center;
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 30px;
}
.btnIcon{
  font-size: 25px;margin-top: 10px;
}
.btnFont{
  margin-top: 10px;margin-left: 5px;
}
.xuanzhongbiankuang{
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 23px;
}
.fontSty{
  background-color:#00C024;
  width: 200px;
  height: 35px;
  line-height: 35px;
  color: white;
  margin-top: 20px;
  text-align: center;
}
</style>
